<template>
  <div class="trademark-service-info">
    <div class="trademark-value-info">
      <div class="value-info">
        <div class="value-title">全链商标服务</div>
        <p>
          商标注册、注册障碍扫除以及后续商标延伸服务，专业平台让商标服务更简单！
        </p>
      </div>
    </div>
    <div>
      <PatentApplication
        :showtitle="true"
        :title="'商标注册服务'"
        :patentlist="$store.state.home.trademark.serviceList"
        :type="'trademark'"
      />
    </div>
    <div class="trademark-qt">
      <Title  :more="'商标续展与其他'" />
      <CopyrightRegistration :copyright="$store.state.home.trademark.renewList" />
    </div>
    <div class="trademark-yz">
      <Title  :more="'商标注册遇阻'" />
      <CopyrightRegistration :copyright="$store.state.home.trademark.registerList" />
    </div>

    <div class="trademark-wh">
      <Title  :more="'商标侵权维护'" />
      <CopyrightRegistration :copyright="$store.state.home.trademark.maintainList" />
    </div>
    <Floating :consultant="$store.state.user.guwen.trademark" />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../comon/Footer.vue";
import PatentApplication from "../comon/PatentApplication.vue";
import Floating from "../../comon/Floating.vue";
import CopyrightRegistration from "../comon/CopyrightRegistration.vue";
import Title from "../comon/Title.vue";
export default {
  name: "TrademarkService",

  components: { CopyrightRegistration, Title, Footer, PatentApplication,Floating },

  directives: {},

  data() {
    return {
      copyright: [
        {
          title: "保护知识成果，遇侵权有据追诉",
        },
        {
          title: "授权他人使用，无风险实现商业盈利",
        },
        {
          title: "高新企业认证条件，获政策补贴",
        },
      ],
      benefitsList: [
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon0.png"),
          title: "独占市场",
          text: "一种产品只要授予专利权，就等于在市场上具有了独占权",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon1.png"),
          title: "技术保护",
          text: "防止他人模仿本企业开发的新技术、新产品",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon2.png"),
          title: "无形资产",
          text: "纯技术一旦授予专利权就变成了工业产权，形成了无形资产，具有了价值，可作为商品出售或转让",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon3.png"),
          title: "荣誉价值",
          text: "企业荣誉、提升价值、评定职称、个人成就实现",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon4.png"),
          title: "政策奖励",
          text: "各地方政府均出台相应文件，对专利申请者进行奖励或补助",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon5.png"),
          title: "企业宣传",
          text: "在宣传时打上专利标志，消费者认为这种商品更具可靠性、信用性，提高企业的知名度",
        },
      ],
      flowList: [
        {
          text: "申请资料准备",
          imgUrl: require("../../assets/patentservice/application-document.png"),
        },
        {
          text: "递交申请文件",
          imgUrl: require("../../assets/patentservice/application-information.png"),
        },
        {
          text: "缴纳申请费用",
          imgUrl: require("../../assets/patentservice/application-fee.png"),
        },
        {
          text: "初步审核阶段",
          imgUrl: require("../../assets/patentservice/review-stage.png"),
        },
        {
          text: "缴纳授权费用",
          imgUrl: require("../../assets/patentservice/authorization-fee.png"),
        },
      ],
      copyrightListWh: [
        {
          title: "单个国家直接申请",
          text: "一份申请，多国享有优先权。",
          money: '',
        },
        {
          title: "PCT国际申请",
          text: "单一国家申请专利是指中国申请人直接到国外的一个国家或一个地区申请专利。",
          money: '',
        },
        {
          title: "PCT国际申请",
          text: "单一国家申请专利是指中国申请人直接到国外的一个国家或一个地区申请专利。",
          money: '',
        },
      ],
      copyrightList: [
        {
          title: "商标变更",
          text: "若商标申请人名称、地址等信息发生变更，应及时向商标局递交变更申请。",
          money: "",
        },
        {
          title: "商标续展",
          text: "注册商标10年有效期满后需要继续使用的，应当在期满前的12个月内申请续展。",
          money: '',
        },
        {
          title: "商标宽展",
          text: "商标专用权期满6个月内为宽展期，宽展期内仍未提出申请的，商标局将注销其注册商标。",
          money: '',
        },
        {
          title: "商标转让",
          text: "单一国家申请专利是指中国申请人直接到国外的一个国家或一个地区申请专利。",
          money: '',
        },
        {
          title: "商标许可备案",
          text: "通过许可使用盘活持有商标资产，除了签约许可合同外，官方备案不可少！",
          money: '',
        },
        {
          title: "商标更正",
          text: "商标专用权期满6个月内为宽展期，宽展期内仍未提出申请的，商标局将注销其注册商标。",
          money: '',
        },
      ],
      lawList: [
        {
          title: "专利申请复审",
          text: "一份申请，多国享有优先权。",
          money: '',
        },
        {
          title: "专利权恢复",
          text: "专利权终止后2个月内，可以向国务院专利行政部门请求恢复权利。",
          money: '',
        },
        {
          title: "专利无效宣告",
          text: "允许对他人的专利不符合专利法规定条件的发明创造专利权提出无效宣告请求。",
          money: '',
        },
        {
          title: "专利无效答辩",
          text: "专利遭遇他人提出无效宣告请求时需要进行。",
          money: '',
        },
        {
          title: "审查意见答复",
          text: "对国知局下发的审查意见通知书进行规范答复。",
          money: '',
        },
      ],
    };
  },

  mounted() {},

  methods: {
    handleChange(value) {
      console.log(value); // { key: "lucy", label: "Lucy (101)" }
    },
  },
};
</script>

<style lang="less">
@import "../../less/trademarkservice.less";
</style>
